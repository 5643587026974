import * as React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

// Components
import Layout from '../../../components/layout/Layout';
import AboutSection from '../../../components/section/About';
import ServiceCard from '../../../components/card/Service';
import CountsSection from '../../../components/section/Counts';
import RealisationsSection from '../../../components/section/Realisations';
import TeamSection from '../../../components/section/Team';
import PartnersSection from '../../../components/section/Partners';
import TestimonialsSection from '../../../components/section/Testimonials';
import ContactSection from '../../../components/section/Contact';

// Services
const ServicesLocalPage = ({ pageContext, data }) => {

  const services = data.services.nodes.map(service => `${service.frontmatter.title.toLowerCase()}`).join(', ');

  const pageSchemas = [
    { 
      type: "organization",
      description: `${data.site.siteMetadata.title} est le spécialiste en ${services} à ${data.city.name} (${data.city.postal_code}).`,
      addressLocality: data.city.name,
      postalCode: data.city.postal_code
    }
  ];

  const headerBackgroundImageRandom = Math.floor(Math.random() * data.services.nodes.length);
  const headerBackgroundImage = data.services.nodes[headerBackgroundImageRandom].frontmatter.image.cover ? data.services.nodes[headerBackgroundImageRandom].frontmatter.image.cover.childImageSharp.fluid : null;
  const headerBackgroundImageStack = [
    headerBackgroundImage,
    `linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))`,
  ].reverse()

  return (
    <Layout
      pageTitle={`Services à ${data.city.name} | ${data.site.siteMetadata.title} - L'entreprise spécialiste du bâtiment à ${data.city.name}`}
      pageDescription={`${data.site.siteMetadata.title} est le spécialiste en ${services} à ${data.city.name} (${data.city.postal_code}).`}
      pageKeywords={`pcc bâtiment, ${data.city.name}, ${data.city.postal_code}, rénovation, construction, ${services}`}
      pageSchemas={pageSchemas}
      headerClassName="header header-layout-type-header-2rows"
    >
      <BackgroundImage
        Tag="section"
        className="page-title divider layer-overlay overlay-dark-8 section-typo-light bg-img-center"
        fluid={headerBackgroundImageStack}
      >
        <div className="container pt-90 pb-90">
          <div className="section-content">
            <div className="row">
              <div className="col-md-12 text-center">
                <h2 className="title text-white">Nos services à {data.city.name}</h2>
                <nav role="navigation" className="breadcrumb-trail breadcrumbs">
                  <div className="breadcrumbs">
                    <span className="trail-item trail-begin">
                      <Link to={`/`}>Accueil</Link>
                    </span>
                    <span><i className="bi bi-chevron-right"></i></span>
                    <span className="trail-item trail-end active">Nos services à {data.city.name}</span>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <AboutSection site={data.site} city={data.city} sectionStyle="4" />
      <section id="services" className="bg-silver-light">
        <div className="container">
          <div className="section-title">
            <div className="row justify-content-md-center">
              <div className="col-md-8">
                <div className="text-center mb-60">
                  <div className="tm-sc tm-sc-section-title section-title section-title-style1 text-center line-bottom-style4-attached-double-lines1">
                    <div className="title-wrapper">
                      <h2 className="title">Nos <span className="text-theme-colored1">services</span> à <span className="text-theme-colored2">{data.city.name}</span></h2>
                      <div className="title-seperator-line"></div>
                      <div className="paragraph">
                        <p>Notre équipe compétente et qualifiée saura vous apporter toutes les informations et le savoir-faire pour la bonne réalisation de votre projet à {data.city.name}.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section-content">
            <div className="row">
              {data.services.nodes.map(service => (
                <ServiceCard key={service.id} data={service} city={data.city} cardStyle="3" cardClassName="col-md-6 col-lg-6 col-xl-4 mb-30" />
              ))}
            </div>
          </div>
        </div>
      </section>
      <RealisationsSection site={data.site} city={data.city} sectionStyle="2" />
      <CountsSection site={data.site} city={data.city} sectionStyle="1"/>
      <TeamSection site={data.site} city={data.city} sectionStyle="3" />
      <PartnersSection site={data.site} city={data.city} sectionStyle="1" />
      <TestimonialsSection site={data.site} city={data.city} sectionStyle="1" />
      <ContactSection site={data.site} city={data.city} sectionStyle="5" />
    </Layout>
  )
}

ServicesLocalPage.propTypes = {
  pageContext: PropTypes.shape({
    city_name: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    city: PropTypes.shape({
      name: PropTypes.string.isRequired,
      city_name: PropTypes.string.isRequired,
    }).isRequired
  }),
}

export const query = graphql`
  query ($city_name: String) {
    site: site {
      siteMetadata {
        siteUrl
        title
        description
        keywords
        image {
          alt
        }
      }
    }
    city : citiesCsv(name: {eq: $city_name}) {
      name
      name_capital
      name_lowercase
      department
      postal_code
    }
    services: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/local/services/" }, 
        frontmatter: { active: { eq: true } }
      },
      sort: {fields: frontmatter___order, order: ASC}
    ) {
      nodes {
        frontmatter {
          title
          description
          image {
            icon {
              childImageSharp {
                gatsbyImageData
                fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            cover {
              childImageSharp {
                gatsbyImageData
                fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            card {
              childImageSharp {
                gatsbyImageData
                fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            alt
            credit {
              text
              link
            }
          }
          home
          active
          date(formatString: "D MMMM YYYY", locale: "fr")
        }
        id
        slug
      }
      totalCount
    }
  }
`

export default ServicesLocalPage